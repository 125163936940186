.canvas-wrapper canvas {
  width: 100% !important;
  height: 100% !important;
}

.main-wrapper {
  height: 100vh;
  align-items: center;
}
  
.canvas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}
.aximo-iconbox-wrap:hover {
  background-color: unset;
}


.buttons {
  text-align: center;
}

.parts-list h3 {
  text-align: center;
}

header.aximo-header-section {
  position: unset;
}

.category-section {
  margin-bottom: 1rem;
}

.category-title {
  margin-bottom: 10px;
}
.aximo-iconbox-wrap {
  margin-bottom: 0;
}

.part {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 2px solid #648338;
  border-radius: 7px;
  transition: transform 0.2s;
  cursor: pointer;
}

.part.selected {
    border: 2px solid #000; 
}

.part img {
  display: block;
  max-width: 100%;
  border-radius: 5px;
}

button {
  width: 100%;
  margin-top: 10px;
}





.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Customize as needed */
}


.arrow {
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px !important;
  background: #BFF747;
  border: 2px solid #000;
  height: 96%;
  width: 20px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: #000;
}
.arrow:hover {
  background: #fff;
  border: 2px solid #000;
  color: #000;
}
.arrow.fa-angle-right {
  right: 0;
}
.swiper-button-prev::after, .swiper-button-next::after {
  content: '';
}
.swiper-button-disabled .arrow {
  opacity: 0;
  pointer-events: none;
}

iframe {
  width: 100%;
  height: 85vh;
}

.iframe-container {
  width: 100%;
  margin: 0 auto;
}
.aximo-hero-thumb {
  position: unset;
}
*::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the handle */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovered */
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #BFF747; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.aximo-hero-thumb img {
  transition: all 0.5s;
  transform: scale(1.1);
}
.aximo-hero-thumb img:hover {
  transform: translate3d(0, 0, 0) scale(1);
}

.aximo-hero-section {
  padding: 135px 0 ;
}
.sticky-top {
  margin-top: 20px;
}
.aximo-social-icon svg {
  fill: white;
}
.aximo-social-icon a:hover svg {
  fill: black;
}
@media (max-width: 768px) {
  .aximo-iconbox-wrap {
    padding: 10px;
  }
  .aximo-pricing-btn2 {
    height: 40px;
  }
  .aximo-default-btn.aximo-header-btn {
    width: 115px;
    margin-top: 10px;
  }
  .aximo-hero-section {
    padding: 70px 0;
  }
}

